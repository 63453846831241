import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { VariableI } from "../../pojo/VariableI";
import { VariableWithOptions } from "../s25-dropdown/single-select/s25.bpe.vars.dropdown.component";
import { S25Help } from "../s25-help/s25.help.service";
import { DropDownItem } from "../../pojo/DropDownItem";
import { BpeVarsUtil } from "./s25.bpe.vars.util";
import { BpeVarsConst } from "./s25.bpe.const";
import { Doc } from "../system-settings/document-management/s25.document.const";

export type BpeVariableData = {
    variable?: VariableWithOptions;
    options: Record<string, any>;
    var?: string;
    preVar?: string;
    format?: VariableI;
};

@TypeManagerDecorator("bpe-vars")
@Component({
    selector: "bpe-vars",
    template: `
        <p>
            Select event details you would like to include and copy the generated variable values into your
            {{ codeMode ? "code" : "template" }}. Clicking a variable link will copy that value to your clipboard.
        </p>

        <label class="dropdownLabel">Standard Event Variables</label>
        <s25-ng-info-message> Basic variables available in all events.</s25-ng-info-message>
        <s25-ng-bpe-variable [state]="event" [type]="'event'" [codeMode]="codeMode" [scope]="scope" />

        <label class="dropdownLabel">Event Roles</label>
        <s25-ng-info-message>
            Functions contacts may perform for an event (such as Emergency Contact, Facilitator, etc.). Select the role
            and the information about it you wish to display.
        </s25-ng-info-message>
        <s25-ng-bpe-variable [state]="roles" [type]="'roles'" [codeMode]="codeMode" [scope]="scope" />

        <label class="dropdownLabel">Custom Attributes</label>
        <s25-ng-info-message>
            Custom data fields used to define additional information for events. Event custom attributes are associated
            with individual event types.
        </s25-ng-info-message>
        <s25-custom-attr-dropdown
            class="c-margin-top--quarter"
            [itemTypeId]="1"
            [(chosen)]="attribute.options.attribute"
            (chosenChange)="setVariable(attribute)"
            [whitelistedTypes]="['F', 'B', 'S', 'D', 'E', '6', '3', 'N', '4', 'X', 'T', 'R', '2']"
        ></s25-custom-attr-dropdown>
        <s25-ng-copy-variable [label]="'Variable'" [text]="attribute.var" />
        <s25-ng-copy-variable [label]="'Pre Variable'" [text]="attribute.preVar" />

        @if (!codeMode) {
            <label class="dropdownLabel">Formattable Date Variables</label>
            <s25-ng-info-message>
                Select the desired variable then select a date or time format. A preview will show the display format.
                <a href="{{ templateHelpLink }}" s25Help [helpTopic]="'template_vars_date'">
                    Read more about date modification.
                </a>
            </s25-ng-info-message>
            <s25-ng-bpe-variable [state]="dates" [type]="'dates'" [codeMode]="codeMode" [scope]="scope" />
        }

        <label class="dropdownLabel">Organization Roles</label>
        <s25-ng-info-message>
            Functions contacts may perform for an organization. Select the role and the information about it you wish to
            display.
        </s25-ng-info-message>
        <s25-toggle-button
            [(modelValue)]="orgRole.options.specific"
            [trueLabel]="'Specific Org'"
            [falseLabel]="'Primary Org'"
            (modelValueChange)="setVariable(orgRole)"
        />
        @if (orgRole.options.specific) {
            <s25-organization-dropdown [(chosen)]="orgRole.options.org" (chosenChange)="setVariable(orgRole)" />
        }
        @if (orgRole.options.specific === false || orgRole.options.org) {
            <s25-ng-bpe-variable [state]="orgRole" [codeMode]="codeMode" [scope]="scope" />
        }

        @if (isToDoTemplate !== 1 && !codeMode) {
            <label class="dropdownLabel">Table Builder</label>
            <s25-ng-info-message> Create a custom table to present desired data from the event. </s25-ng-info-message>
            <s25-ng-table-builder-variable [type]="type" [scope]="scope" />
        }

        @if (isToDoTemplate !== 1 && !codeMode) {
            <label class="dropdownLabel">List Builder</label>
            <s25-ng-info-message> Create a custom list to present desired data from the event. </s25-ng-info-message>
            <s25-ng-list-builder-variable [type]="type" />
        }

        @if (type === "document") {
            <div class="documentVariables">
                <h3>Document Variables</h3>

                <label class="dropdownLabel">General Variables</label>
                <s25-ng-info-message>
                    Special variables for information tables and a variety of other data groupings.
                </s25-ng-info-message>
                <s25-ng-bpe-variable [state]="ws" [type]="'ws'" [codeMode]="codeMode" [scope]="scope" />

                <label class="dropdownLabel">Table Variables</label>
                <s25-ng-info-message>
                    These variables render as tables. Some of the tables have optional checkboxes to present different
                    information.
                </s25-ng-info-message>
                <s25-ng-bpe-variable [state]="tables" [type]="'wsTables'" [codeMode]="codeMode" [scope]="scope" />

                <label class="dropdownLabel">Pricing Variables</label>
                <s25-ng-info-message> Pricing information about the event.</s25-ng-info-message>
                <s25-ng-bpe-variable [state]="pricing" [type]="'pricing'" [codeMode]="codeMode" [scope]="scope" />

                <label class="dropdownLabel">Organization Variables</label>
                <s25-ng-info-message>
                    Variables containing information about the primary organization of the event.
                </s25-ng-info-message>
                <s25-ng-bpe-variable [state]="wsOrg" [type]="'wsOrg'" [codeMode]="codeMode" [scope]="scope" />

                <label class="dropdownLabel">Organization Custom Attributes</label>
                <s25-ng-info-message>
                    Custom data fields used to define additional information for organizations. Organization custom
                    attributes are associated with individual organization types.
                </s25-ng-info-message>
                <s25-custom-attr-dropdown
                    class="c-margin-top--quarter"
                    [itemTypeId]="2"
                    [(chosen)]="orgAttribute.options.attribute"
                    (chosenChange)="setVariable(orgAttribute)"
                    [whitelistedTypes]="['F', 'B', 'S', 'D', 'E', '6', '3', 'N', '4', 'X', 'T', 'R', '2']"
                ></s25-custom-attr-dropdown>
                <s25-ng-copy-variable [label]="'Variable'" [text]="orgAttribute.var" />

                @if (scope === "invoice") {
                    <label class="dropdownLabel">Invoice</label>
                    <s25-ng-info-message> General information about the invoice.</s25-ng-info-message>
                    <s25-ng-bpe-variable [state]="invoice" [type]="'invoice'" [codeMode]="codeMode" [scope]="scope" />

                    <label class="dropdownLabel">Invoice Totals</label>
                    <s25-ng-info-message>
                        Variables containing totals for prices, tax, adjustments, and more.
                    </s25-ng-info-message>
                    <s25-ng-bpe-variable
                        [state]="invoiceTotals"
                        [type]="'invoiceTotals'"
                        [codeMode]="codeMode"
                        [scope]="scope"
                    />

                    <label class="dropdownLabel">Invoice Payments</label>
                    <s25-ng-info-message>
                        Variables containing information pertaining to payments made towards the event.
                    </s25-ng-info-message>
                    <s25-ng-bpe-variable
                        [state]="invoicePayment"
                        [type]="'invoicePayment'"
                        [codeMode]="codeMode"
                        [scope]="scope"
                    />
                }
                @if (scope === "reservation") {
                    <label class="dropdownLabel">Reservation Variables</label>
                    <s25-ng-info-message>
                        Information about specific occurrences and segments of an event.
                    </s25-ng-info-message>
                    <s25-ng-bpe-variable [state]="wsRsv" [type]="'wsRsv'" [codeMode]="codeMode" [scope]="scope" />

                    <label class="dropdownLabel">Reservation Formattable Date Variables</label>
                    <s25-ng-info-message>
                        Select the desired variable then select a date or time format. A preview will show the display
                        format.
                        <a href="{{ documentHelpLink }}" s25Help [helpTopic]="'document_vars_date'">
                            Read more about date modification.</a
                        >
                    </s25-ng-info-message>
                    <s25-ng-bpe-variable [state]="dates" [type]="'rsvDates'" [codeMode]="codeMode" [scope]="scope" />
                }

                <label class="dropdownLabel">Resources by Id</label>
                <s25-ng-info-message>
                    Determine if you want to "Include All" then optionally check the box. Use the "Select Resources"
                    button to choose one or more resources. Then, choose display options.
                </s25-ng-info-message>
                <s25-ng-bpe-variable [state]="resById" [type]="'resById'" [codeMode]="codeMode" [scope]="scope" />

                <label class="dropdownLabel">Resources by Category</label>
                <s25-ng-info-message>
                    Determine if you want to "Include All" then optionally check the box. Use the "Select Categories"
                    button to choose one or more categories. Then, choose display options.
                </s25-ng-info-message>
                <s25-ng-bpe-variable [state]="resByCat" [type]="'resByCat'" [codeMode]="codeMode" [scope]="scope" />
            </div>
        }
    `,
    styles: `
        .dropdownLabel {
            display: inline-block !important;
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0;
            width: min(100%, 225px);
        }

        .documentVariables {
            margin-top: 1em;
            border-top: 1px solid rgba(255, 255, 255, 0.24);
            padding-top: 1em;
        }

        ::ng-deep bpe-vars .ngDropdownPaginated {
            max-width: 250px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.Emulated,
})
export class BpeVarsComponent implements OnChanges {
    //Document Management of Event Save Emails
    @Input() type: string = "email";
    //In document management, is the report scoped to the event or reservation
    @Input() scope: Doc.ScopeId = "event";
    @Input() isToDoTemplate?: number = 0;
    @Input() codeMode: boolean;

    attribute: BpeVariableData = { options: {}, variable: BpeVarsConst.attribute };
    orgAttribute: BpeVariableData = { options: {}, variable: BpeVarsConst.orgAttribute };
    event: BpeVariableData = BpeVarsUtil.newVariableData();
    dates: BpeVariableData = BpeVarsUtil.newVariableData();
    roles: BpeVariableData & { role?: DropDownItem } = BpeVarsUtil.newVariableData();
    //ws variables used in document management
    ws: BpeVariableData = BpeVarsUtil.newVariableData();
    wsRsv: BpeVariableData = BpeVarsUtil.newVariableData();
    wsOrg: BpeVariableData = BpeVarsUtil.newVariableData();
    pricing: BpeVariableData = BpeVarsUtil.newVariableData();
    invoice: BpeVariableData = BpeVarsUtil.newVariableData();
    invoiceTotals: BpeVariableData = BpeVarsUtil.newVariableData();
    invoicePayment: BpeVariableData & { paymentType?: VariableI } = BpeVarsUtil.newVariableData();
    tables: BpeVariableData = BpeVarsUtil.newVariableData();
    templateHelpLink = S25Help.getHelpUrl("template_vars_date");
    documentHelpLink = S25Help.getHelpUrl("document_vars_date");
    resById: BpeVariableData = BpeVarsUtil.newVariableData();
    resByCat: BpeVariableData = BpeVarsUtil.newVariableData();
    orgRole: BpeVariableData = { options: {}, variable: BpeVarsConst.organizationRole };

    protected readonly S25Help = S25Help;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.scope) this.cd.detectChanges();
    }

    setVariable(state: BpeVariableData) {
        BpeVarsUtil.setVariable(state, this.codeMode);
        this.cd.detectChanges();
    }
}
